*, *::before, *::after {
    box-sizing: border-box;
}

body {
    margin: 0;
}

.carousel {
    width: 50vw;
    height: 50vh;
    position: relative;
}

.carousel > ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.slide {
    position: absolute;
    inset: 0;
    opacity: 1;
}

.slide > img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.slide[data-active] {
    opacity: 1;

}

.carousel-button {
    position: absolute;
    z-index: 2;
    background: none;
    border: none;
    font-size: 3rem;
    top: 50%;
    transform: translateY(-50%);
    color: rgba(255, 255, 255, 0.75);
    cursor: pointer;
    border-radius: 0.5rem;
    padding: .5 .5rem;
    background-color: rgba(0, 0, 0, 0.5);
}

.carousel-button:hover,
.carousel-button:focus {
    color: white;
    background-color: rgba(0, 0, 0, .1);
}

.carousel-button.prev {
    left: 1rem;
}

.carousel-button.next {
    right: 1rem;
}




ul {
    list-style: none;
}

.nav {
    background: linear-gradient(-45deg, #c6daf0, #dffca7, #e8e5ee, #fffdfd);
    animation: gradient 15s ease infinite;
    color: black;
    align-items: left;
    gap: 2rem;
    padding: 1rem 1rem;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.site-title {
    font-size: 2rem;
    margin-bottom: 2.5rem;
}

.nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
    gap: 1rem;
}

.nav a {
    color: inherit;
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0.5rem;
}

.nav li {
    font-size: 1.5rem;
    font-weight: 200;
}

.home a {
    font-weight: 400;
}

.nav li:hover {
    font-weight: 400;
}

.nav li:visited {
    font-weight: 400;
}

h1 {
    margin: 1.25rem;
    font-weight: 200;
}